<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('building_name')">
                            <b-form-input :validate-error="errors[0]" v-model="formData.name">
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="address" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('address')">
                            <b-form-input :validate-error="errors[0]" v-model="formData.address">
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="cleaning_schedule_start_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('cleaning_schedule_start_date')">
                            <select-date  v-model="formData.cleaning_schedule_start_date"
                                          :validation-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="cleaning_schedule_repeat" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('cleaning_schedule_repeat')">
                            <b-input-group class="mb-3">
                                <b-form-input
                                    v-model="formData.cleaning_schedule_repeat"
                                    type="number"
                                >
                                </b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate"
import HousingBuilding from "@/services/HousingBuildingServices";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
                name: null,
                address: null,
                cleaning_schedule_start_date:null,
                cleaning_schedule_repeat:null
            },
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
     created() {
         this.get(this.formId)
    },
    methods: {
     async get(id) {
          HousingBuilding.show(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        name: data.name,
                        address: data.address,
                        cleaning_schedule_start_date:data.cleaning_schedule_start_date,
                        cleaning_schedule_repeat:data.cleaning_schedule_repeat
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                HousingBuilding.update(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
